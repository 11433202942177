<template>
  <article
    id="page"
    :class="['view', 'fill-height', 'w-100']"
  >
    <!--LOADING  -->
    <v-progress-linear
      v-if="pending || meta.loading"
      :active="pending || meta.loading"
      :color="config?.color"
      indeterminate
    />
    <!-- <code v-if="data"><pre>{{ data }}</pre></code> -->

    <!-- slot breadcrumbs -->

    <v-system-bar
      v-if="!pending && !meta.loading && breadcrumbs?.length > 1"
      :color="shinethrough ? 'transparent' : 'surface'"
      absolute
      density="compact"
      class="justify-start"
    >
      <v-breadcrumbs
        :items="breadcrumbs"
        bg-color="transparent"
        density="compact"
      >
        <template #divider>
          <v-icon
            icon="mdi-chevron-right"
            size="sm"
          />
        </template>
      </v-breadcrumbs>
    </v-system-bar>

    <template v-if="!meta.loading && data">
      <!-- FORBIDDEN -->
      <mtf-forbidden v-if="meta.forbidden" />

      <!-- UNAVAILABLE -->
      <mtf-empty v-else-if="meta.unavailable" />

      <!-- ALL GOOD -->
      <template v-else-if="meta.available">
        <!-- slot Above Main -->
        <v-row
          v-if="hasSectionsAbove"
          no-gutters
          class="slot slot-above-main"
        >
          <v-col tag="section">
            <slot name="above">
              <mtf-sections
                v-bind="above"
                variant="above"
              />
            </slot>
          </v-col>
        </v-row>

        <!-- Main + Aside slot-->
        <v-row
          v-if="hasSectionsMain || hasSectionsAside"
          no-gutters
          class="slot slot-main"
        >
          <!-- Main -->
          <v-col tag="section">
            <slot name="default">
              <mtf-sections
                v-if="hasSectionsMain"
                v-bind="main"
              />
            </slot>
          </v-col>

          <!-- Aside -->
          <v-col
            v-if="hasSectionsAside"
            cols="12"
            :sm="aside?.cols?.sm || aside?.cols || 6"
            :md="aside?.cols?.md || aside?.cols || 5"
            :lg="aside?.cols?.lg || aside?.cols || 4"
            :xl="aside?.cols?.xl || aside?.cols || 3"
            class="position-relative"
            tag="aside"
          >
            <slot name="aside">
              <mtf-sections
                v-bind="aside"
                variant="aside"
                class="h-100"
                :styles="['align-content-start', aside?.styles]"
              />
            </slot>
          </v-col>
        </v-row>

        <!-- slot Below Main -->
        <v-row
          v-if="hasSectionsBelow"
          no-gutters
          class="slot slot-below-main"
        >
          <v-col tag="section">
            <slot name="below">
              <mtf-sections
                v-bind="below"
                variant="below"
              />
            </slot>
          </v-col>
        </v-row>
      </template>

      <!-- DEBUG -->
      <mtf-debug
        v-if="debug"
        title="Debug Page"
        :loading="meta.loading"
        :meta="meta"
        :state="state"
        :error="state?.context?.error"
        class="ma-4"
        color="grey-lighten-3"
      />
    </template>

    <mtf-empty
      v-else-if="error || errors"
      icon="mdi-exclamation-thick"
      title="Oops!"
      description="Looks like something went wrong..."
      :note="error || errors"
      actions=""
    />
  </article>
</template>

<script setup>
import { map } from 'lodash-es';
import {
  useMatterificContent,
  useMatterificMenus,
  useMatterificContentProvider,
  onMounted
} from '#imports';

const matterific = useMatterificContent();
const menus = useMatterificMenus();
const query = matterific.getQuery();
// const data = null, error= null, pending = true;
const { data, pending, error } = await useFetch(query.url, query.options);
// Page not found, set correct status code on SSR and thow a fatal NUXT error
if (!data.value && error.value?.statusCode === 404) {
  console.error('Page not found', query);
  throw createError({
    statusCode: 404,
    statusMessage: 'Page Not Found',
    fatal: true
  });
}

// Now update any Menus + settings
await menus.update(data.value?.settings);

const {
  debug,
  state,
  config,
  errors,
  meta,
  seo,
  above,
  main,
  aside,
  below,
  shinethrough,
  hasSectionsAbove,
  hasSectionsAside,
  hasSectionsMain,
  hasSectionsBelow
} = useMatterificContentProvider(data.value);

// ---------------------
// Shinthrough
useHead({
  bodyAttrs: {
    class: computed(() => (shinethrough.value ? 'shinethrough' : ''))
  }
});

// ---------------------
// SEO Meta
useSeoMeta(seo);

// ---------------------
// SEO Schema
// useSchemaOrg([
//   defineOrganization({
//     name: 'My company',
//     logo: '/logo.png',
//     sameAs: [
//       'https://twitter.com/company',
//     ]
//   }),
//   defineWebSite({/* ... */ }),
//   defineWebPage(),
// ]);

// ---------------------
// Breadcrumbs
const breadcrumbItems = useBreadcrumbItems();

const breadcrumbs = computed(() =>
  map(breadcrumbItems.value, (item, index) => {
    return {
      ...item,
      title: item.label
    };
  })
);

useSchemaOrg([
  defineBreadcrumb({
    itemListElement: breadcrumbItems
  })
]);

onMounted(() => {
  // todo : set up live updates from Firebase
  // refresh();
});
</script>

<script>
export default {
  name: 'MtfPage',
  inheritAttrs: false,
  customOptions: {}
};
</script>

<style lang="scss">
$header-height: 64px !default;
$footer-height: 106px !default;

.shinethrough {
  #main {
    --v-layout-top: 0 !important;
  }
  #page {
    // margin-top: -$header-height;

    > .slot:first-child .sections > .section:first-child {
      padding-top: $header-height !important;
    }
  }
}

#main {
  // --v-layout-top: $header-height !important;
}

#page {
  min-height: calc(100vh - #{$header-height + $footer-height});
}
</style>
