import matterificServices from '@matterific/services/index.js';

export default () => {
  const entity = 'page';
  const api = matterificServices(entity); // the api that will do CRUD on the entity

  // expose managed state as return value, but our internals are frozen so they cant be mutated
  return {
    orchestrate: () => Promise.reject(), // we dont need to orchestrate content
    materialize: api.materialize,
    check: api.check,
    hydrate: ({ id, baseModel, skipFetch }) => api.hydrate(id, baseModel, skipFetch),
    validate: () => Promise.resolve(), // we dont need to validate content
    checkAuth: api.checkAuth
  };
};
