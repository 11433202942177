/* eslint-disable no-unused-vars */
import { computed, ref, unref, watch, watchEffect, provide, inject } from 'vue';
import { useRoute } from '#app';
import { entityMachine } from '@matterific.entities/machines';
import contentServices from '../services';
import { useRuntimeConfig } from '#imports';
import { useMachine, useSelector } from '@xstate/vue';
import { pickBy, isFunction, omit, pick } from 'lodash-es';

// Content is special in that it is hydrated by Nuxt Pages via their path,
// If found, we need to create the machine and provide it to the page
// Using the data/context from the page itself
// Pages will have their data set as the BaseModel, to allow the machine to hydrate
// Pages can also have additional permissions and conditions set
const useMatterificContentMachine = (context) => {
  const api = contentServices();
  const debug = !!useRuntimeConfig().public?.debug?.content;
  const safeContext = {
    id: context?.id,
    config: pick(context, ['title', 'description', 'note']),
    conditions: context?.settings?.conditions,
    permissions: context?.settings?.permissions,
    baseModel: omit(context, [
      'id',
      'name',
      'path',
      'title',
      'description',
      'note',
      'settings.conditions',
      'settings.permissions'
    ]),
    skipFetch: true,
    debug
  };

  const machineConfig = entityMachine('content');
  return useMachine(machineConfig, {
    context: safeContext,
    services: pickBy(api, isFunction),
    devTools: debug
  });
};

export const useMatterificContentProvider = (data, context) => {
  const machine = useMatterificContentMachine(unref(data));
  if (!machine) {
    throw new Error('Page must be used within a Matterific Content, no machine provided');
  }

  const { state, service, send } = machine;

  const route = useRoute();
  const debug = useSelector(service, (state) => state.context.debug);
  const config = useSelector(service, (state) => state.context.config);
  const settings = useSelector(service, (state) => state.context.model?.settings);
  const seo = useSelector(service, (state) => state.context.model?.seo);
  const page = useSelector(service, (state) => state.context.model);
  const errors = useSelector(service, (state) => state.context.validationErrors);

  // reactive example
  // ogImage: () => someData.value?.image

  // We need to provide the machine to the Page that will be rendered
  provide('errors', errors);
  provide('pageContext', {
    ...route.params,
    path: route.path,
    config: config.value,
    settings: settings.value
  });
  // ----------------
  // META DATA
  const meta = ref({
    done: undefined,
    available: undefined,
    loading: true,
    unavailable: undefined,
    forbidden: undefined
  });

  //  Update meta data when state changes
  watchEffect(() => {
    meta.value.done = state.value.done;
    meta.value.loading = ['materializing', 'pending', 'hydrating'].some(state.value.matches);
    meta.value.available = state.value.matches('available');
    meta.value.unavailable = state.value.matches('unavailable');
    meta.value.forbidden = ['unavailable.permissions', 'unavailable.conditions'].some(
      state.value.matches
    );

    // // If the page is done, wait 3 seconds before emitting cancel
    // if (state.value.done) {
    //   delay(context.emit, 3000, 'cancel');
    // }
  });

  return {
    // machine,
    state,
    service,
    send,
    // ----------------
    // Getters : these are computed so they cannot be accidentally mutated
    debug: computed(() => debug.value),
    page: computed(() => page.value),
    config: computed(() => config.value),
    errors: computed(() => errors.value),
    meta: computed(() => meta.value),
    seo: {
      title: () => seo.value?.title || page.value?.title || null,
      ogTitle: () => seo.value?.title || page.value?.title || null,
      description: () => seo.value?.description || page.value?.description || null,
      ogImage: () => seo.value?.image || null,
      twitterCard: () => seo.value?.twitterCard || 'summary_large_image'
    },

    // ----------------
    above: computed(() => page.value?.above),
    main: computed(() => page.value?.main),
    aside: computed(() => page.value?.aside),
    below: computed(() => page.value?.below),
    // --------
    shinethrough: computed(() => settings.value?.header?.shinethrough || false),
    // --------
    hasSections: computed(
      () =>
        !!page.value?.above?.sections?.length ||
        !!page.value?.main?.sections?.length ||
        !!page.value?.aside?.sections?.length ||
        !!page.value?.below?.sections?.length
    ),
    hasSectionsAbove: computed(() => !!page.value?.above?.sections?.length),
    hasSectionsMain: computed(
      () => !!page.value?.main?.sections?.length || !!page.value?.aside?.sections?.length
    ),
    hasSectionsAside: computed(() => !!page.value?.aside?.sections?.length),
    hasSectionsBelow: computed(() => !!page.value?.below?.sections?.length)
  };
};
