import { unref } from 'vue';
import { useFetch } from '#app';
import { useMatterificStore } from '@matterific/store';
import { storeToRefs } from 'pinia';
import { forEach, get, set, isObject, camelCase, kebabCase } from 'lodash-es';

const apiPath = '/api/menus';

const appMenus = {
  primary: 'header.menuPrimary',
  drawer: 'header.menuDrawer',
  rail: 'rail.menu',
  sidebar: 'sidebar.menu',
  footer: 'footer.menuFooter',
  compliance: 'footer.menuCompliance',
  social: 'footer.menuSocial'
};

// Content Module uses the Matterific API Composable to fetch data from the Database
// This module fetches data from the Pages Collection
export default () => {
  const store = useMatterificStore();
  const { settings } = storeToRefs(store);
  // console.log('useMatterificMenus');

  async function update(data) {
    const menusToUpdate = [];
    const menuPromises = [];

    // 1st update the settings withthe new page data
    // store.updateSettings({ pending: true });
    settings.value.pending = true;

    // then check if we have any missing menus we need to fetch/hydrate
    forEach(appMenus, (path, name) => {
      const menuRef = get(data, path);
      if (!isObject(menuRef)) {
        menusToUpdate.push(name);
        // now indicate that we are loading this menu
        set(data, path, { loading: true });

        const query = {
          url: `${apiPath}`,
          options: {
            key: camelCase(`menu-${kebabCase(name)}`),
            method: 'post',
            query: { name }
            // lazy: true
            // transform: (input) => first(input)
            // pick ?: string[]
            // watch ?: WatchSource[]
          }
        };

        // console.log('useMatterificMenus', { query });
        const promise = useFetch(query.url, query.options);
        menuPromises.push(promise);
      }
    });

    const results = await Promise.allSettled(menuPromises);
    forEach(results, (result, index) => {
      const menu = menusToUpdate[index];
      const path = get(appMenus, menu);
      // console.log('useMatterificMenus', { menu, path, data: result.value?.data, error: result.value?.error, result });
      set(data, path, unref(result.value?.data));
    });

    // console.log('useMatterificMenus', { data });
    store.updateSettings({ ...data, pending: false });

    // console.log('useMatterificMenus', { menusToUpdate });
    return data;
  }

  return {
    update
  };
};
