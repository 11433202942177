import { useRoute } from '#app';
import { usePrepend } from '@matterific/utils';
import { forEach, kebabCase, camelCase, omit } from 'lodash-es';

export const useMatterificContent = () => {
  const route = useRoute();
  const apiPath = '/api/content';

  const getQuery = () => {
    let slug = route.path;
    let params = omit(route.params, 'slug');

    // now strip any other route params from the slug
    forEach(params, (value) => {
      slug = slug.replace(usePrepend(value, '/'), '');
    });

    // ensure we start with a slash
    slug = usePrepend(slug, '/');

    // console.debug('useMatterificContent', { url: `${apiPath}/${slug}`, slug, params });

    return {
      url: `${apiPath}`,
      options: {
        key: camelCase(`content-${kebabCase(slug) || 'index'}`),
        query: { slug, params }
        // transform: (input) => first(input)
        // pick ?: string[]
        // watch ?: WatchSource[]
      }
    };
  };

  return {
    getQuery
  };
};
